<template>
  <page-section
      id="videos"
      heading="Lots of ways to expand your play"
      heading-alignment="center"
      padding-bottom="medium"
      theme="green">
    <VideoGallery :videos="[
            {
              url: 'https://vimeo.com/797536678',
              title: 'Legit Brass',
              description: 'Playing legit music on the Tilt',
              image: 'images/MouretRondeau.jpg'
            },
            {
              url: 'https://vimeo.com/885971947',
              title: 'Jazz Standards',
              description: 'Playing Jazz Live',
              image: 'images/YouDontKnowWhatLoveIs.jpg'
            },
            {
              url: 'https://vimeo.com/686743084',
              title: 'Rock',
              description: 'Surprize your audience!',
              image: 'images/Chameleon.jpg'
            },
            {
              url: 'https://vimeo.com/806832653',
              title: 'Techno',
              description: 'Flexible, expressive play!',
              image: 'images/OmensOfLove.jpg'
            }
        ]"/>
  </page-section>
</template>

<script>
import VideoGallery from "@/components/VideoGallery.vue";

export default {
  components: {
    VideoGallery,
  }
}
</script>