<script>

export default {
  name: "IntroSection",
}
</script>

<template>
  <page-section
      id="about"
      ref="about">
    <div class="row section-text">
      <div class="col-md-12 col-lg-4 mb-md-50 mb-xs-30">
        <div class="lead-alt wow linesAnimIn" data-splitting="lines">
          DigiBrass expands the universe of trumpet playing.
        </div>
      </div>
      <div class="col-md-5 col-lg-4 mb-sm-50 mb-xs-30 wow linesAnimIn" data-splitting="lines">
        Sounds big, right?<br> <br>
        With this digital instrument, you can do more than you've ever dreamed of with your analog horn.
      </div>
      <div class="col-md-5   col-lg-4 mb-sm-50 mb-xs-30 wow linesAnimIn" data-splitting="lines">
        Want to explore unlimited digital sounds? What about unlimited range? How about experiencing a burst of
        joy like when you first picked up the trumpet? All of this and more - no chops required.<br> <br>

        <div class="local-scroll wow fadeInUpShort">
          <a class="btn btn-mod btn-color btn-medium btn-round me-md-1" href="/Tilt">Learn about the Tilt</a>
          <a class="btn btn-mod btn-color btn-medium btn-round me-md-1" href="#newsletter">Sign me up</a>
        </div>
      </div>
    </div>
  </page-section>
</template>

<style scoped>

</style>