<template>
  <main id="main">
    <!-- Home Section -->
    <section id="home" class="home-section bg-dark-alfa-70 parallax-5" data-background="images/bg-FW.jpg">
      <div class="container fixed-height-medium d-flex align-items-center pt-100 pb-100">

        <!-- Video BG Init Josh Isn't She Lovely--->
        <div class="player"
             data-property="{videoURL:'https://youtu.be/BAe9kjyrlWQ',containment:'#home',autoPlay:true, showControls:false, showYTLogo: false, mute:true, startAt:5, opacity:1}">
        </div>
        <!--End Video BG Init -->

        <!-- Hero Content -->
        <div class="home-content">
          <a href="tilt" target="_self"><img alt="Primary Wordmark" src="images/WordmarkDarkBox.png" width=" 75%"></a>
          <h2 class="hs-line-7 mb-60 mb-xs-40 pt-30 wow fadeInUpShort" data-wow-delay=".2s">Expand Your Play<br>_________________<br>Now Version 2.0</h2>
          <div class="local-scroll mb-20 wow fadeInUpShort" data-wow-delay=".3s">
            <!--  <a href="https://vimeo.com/683353153" class="btn btn-mod btn-color btn-xl btn-round me-md-1 lightbox mfp-iframe"><i class="fa fa-play-circle"></i>  Play Video</a>-->
          </div>
        </div>
        <!-- End Hero Content -->

        <!-- Scroll Down -->
        <div class="local-scroll scroll-down-wrap wow fadeInUpShort" data-wow-offset="0">
          <a class="scroll-down" href="#about"><i class="scroll-down-icon"></i><span class="sr-only">Scroll to the next section</span></a>
        </div>
        <!-- End Scroll Down -->
      </div>
    </section>
    <!-- End Home Section -->

    <social-feed-section/>

    <rule/>

    <intro-section/>

    <rule/>

    <intro-videos-section/>

    <rule/>

    <email-sign-up-section/>

    <rule/>

    <contact-form-section/>

    <rule/>
    <!-- End Divider -->
  </main>
  <!-- End Page Wrap -->
</template>

<script>
import SocialFeedSection from "@/sections/SocialFeedSection.vue";
import ContactFormSection from "@/sections/ContactFormSection.vue";
import IntroSection from "@/sections/IntroSection.vue";
import IntroVideosSection from "@/sections/IntroVideosSection.vue";
import Rule from "@/components/Rule.vue";
import EmailSignUpSection from "@/sections/EmailSignUpSection.vue";

export default {
  name: 'Index',
  components: {EmailSignUpSection, Rule, IntroVideosSection, IntroSection, SocialFeedSection, ContactFormSection}
}
</script>
