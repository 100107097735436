<template>
  <section class="small-section">
    <div class="container relative">
      <AnimateIn class="row" effect="fadeInUpShort">
        <div id="8a153a04_1645461022" class="powr-social-feed"></div>
      </AnimateIn>
    </div>
  </section>
</template>

<script>

export default {
  mounted() {
    if (window.init_lightbox)
      window.init_lightbox();
  }
}
</script>