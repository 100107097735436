<template>
<!-- Skip to Content -->
        <a href="#main" class="btn skip-to-content">Skip to Content</a>
        <!-- End Skip to Content -->
        <!-- Page Wrap -->
        <div class="page bg-dark light-content" id="top">
            <!-- Navigation panel -->
            <nav class="main-nav dark transparent stick-fixed wow-menubar">
                <div class="full-wrapper relative clearfix">
                    <!-- Logo ( * your text or image into link tag *) -->
                    <div class="nav-logo-wrap local-scroll">

                        <a href="/#home" class="logo">
                            <img src="images/logo-white.png" alt="DigiBrass logo" width="188" height="37" />
                        </a>

                    </div>
                    <!-- Mobile Menu Button -->
                    <div class="mobile-nav" role="button" tabindex="0">
                        <i class="fa fa-bars"></i>
                        <span class="sr-only">Menu</span>
                    </div>
                    
                    <!-- Main Menu -->
                    <div class="inner-nav desktop-nav">
                        <ul class="clearlist scroll-nav local-scroll">
                            <!-- <li class="active"><router-link to="/">Home</router-link></li> -->
                          <li><a href="/Tilt">The Tilt</a></li>
                          <!-- <li><a href="/shop">Shop</a></li>  -->
                          <li><a href="https://digibrass.myshopify.com">Shop</a></li> 
                            <li><a href='/#videos'>Videos</a></li>
                            
                            <!--<li><router-link to="/user-page" v-if="requestUser">Tilts</router-link></li>
                            <li><a href='#'  @click="logout()" v-if="requestUser">Signout</a></li> -->
                            
                            <!-- Item With Sub -->
                                <li>
                                <a href="#" class="mn-has-sub">Support <i class="mn-has-sub-icon"></i></a>
                                
                               
                                <ul class="mn-sub to-right">
                                    
                                   <!-- <li><a href="/FAQ">FAQ</a></li> -->
                                   <li> <a href="https://Learn.DigiBrass.com">Learn.DigiBrass.com</a></li>
                                    <li> <a href="/ReturnsAndWarranty">Returns and Warranty</a></li>
                                    <li><a href="/SupportTicket">Submit a Support Ticket</a></li>
                                    <li><a href="/ResourceList">Other Resources</a></li>
                                    
                                </ul>
</li>
                            <!-- End Item With Sub --> 

                            <li><a href='/#newsletter'>Signup</a></li>     
                             <li>
                                <a href="#" class="mn-has-sub">Contact <i class="mn-has-sub-icon"></i></a>
                                <!-- Sub -->
                                <ul class="mn-sub to-left">
                                    
                                    <li>
                                        <a href="/#contact">Contact Us</a>
                                    </li>
                                    <li>
                                        <a href="/#newsletter">Email List Sign Up</a>
                                    </li>
                                </ul>
                                <!-- End Sub -->
                                
                            </li>
                            <!-- End Item With Sub -->
                            <li><a href="https://www.facebook.com/digibrass" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="https://www.instagram.com/digibrass" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>
                    <!-- End Main Menu -->
                </div>
            </nav>
            <!-- End Navigation panel -->
        </div>  
        <router-view />
         <!-- Footer -->
            <footer class="page-section bg-dark-lighter light-content footer pb-100 pb-sm-50">
       
       <!-- footer link list -->
        <div class="container">
                        
                        <div class="row">
                            
                            <div class="col-sm-4 mb-40">
                                
                                <div class="footerlink">
                                    <h5>Company</h5>
                                      <!-- <ul><a href="/About" target="_self">About DigiBrass</a></ul> -->
                                       <ul><a href="/login">Admin Login</a></ul>                             
                                        </div>
                                
                            </div>
                
                            <div class="col-sm-4 mb-40">
                                
                                <div class="footerlink">
                                    <h5>Information</h5>
                                       <!-- <ul><a href="/FAQ" target="_self">FAQ</a></ul>   -->
                                        <ul><a href="/ReturnsAndWarranty" target="_self">Returns and Warranty</a></ul>
                                        <ul><a href="/QuickStart" target="_self">Quick Start Guide</a></ul>
                                        <ul><a href="/Documentation" target="_self">Documentation</a></ul>
                                        <ul><a href="/PrivacyPolicy" target="_self">Privacy Policy</a></ul> 
                                        <ul><a href="/Legal" target="_self">Legal</a></ul>                               
                                        </div>
                                
                            </div>

                            <div class="col-sm-4 mb-40">
                                
                                <div class="footerlink">
                                    <h5>Social</h5>
                                    <ul><a href="https://www.facebook.com/digibrass/" title="Facebook" target="_blank">Facebook</a></ul>   
                                    <ul><a href="https://www.instagram.com/digibrass/" title="Instagram" target="_blank">Instagram</a></ul>  
                                    <ul><a href="https://www.vimeo.com/digibrass/" title="Vimeo" target="_blank">Vimeo</a></ul>                               
                                    </div>
                                
                            </div>

                         </div>
</div>
<!-- end footer link list-->

                <div class="container">
                     <!-- Social Links -->
                    <div class="footer-social-links mb-90 mb-xs-40">
                        <a href="https://www.facebook.com/digibrass/" title="Facebook" target="_blank"><i class="fab fa-facebook-f"></i> <span class="sr-only">Facebook</span></a>
                        <a href="https://www.instagram.com/digibrass/" title="Instagram" target="_blank"><i class="fab fa-instagram"></i> <span class="sr-only">Instagram</span></a>
						<a href="https://www.vimeo.com/digibrass/" title="Vimeo" target="_blank"><i class="fab fa-vimeo"></i> <span class="sr-only">Vimeo</span></a>
                    </div>
                    <!-- End Social Links -->
                     <!-- Footer Text -->
                    <div class="footer-text">
                        <!-- Copyright -->
                        <div class="footer-copy">
                            <a href="index.html">© DigiBrass LLC 2023</a>.
							<p>Music innovation from the Northwoods of Wisconsin</p>
                        </div>
                        <!-- End Copyright -->
                    </div>
                    <!-- End Footer Text --> 
                </div>
                <!-- Top Link -->
                <div class="local-scroll">
                     <a href="#top" class="link-to-top"><i class="link-to-top-icon"></i></a>
                </div>
                 <!-- End Top Link -->
            </footer>
            <!-- End Footer -->
</template>

<script>
import { signOut } from '@firebase/auth';
import { getAuth, onAuthStateChanged} from "firebase/auth";
import { getAnalytics, logEvent } from 'firebase/analytics';

export default {
    name: 'App',
    data() {
      return {
          userOn: null,
          userOff: true,
          requestUser: null,   
      }
    },
    created () { 
        const auth = getAuth();
        this.userOn = onAuthStateChanged(auth, (user) => {
            if (user) {
            this.userOn = user.email;
            //console.log(this.userOn)
            //this.userOff = false;
            } else {
            // this.userOn = null;
            // this.userOff = true;
            }
        });

        this.setRequestUser(); 
   
    },
    mounted () {
        const script = document.createElement("script");
        script.setAttribute("src", "js/all.js");
        document.head.appendChild(script);
        const analytics = getAnalytics()
        logEvent(analytics, 'Homepage viewed')
    },
    methods: {
        logout() {
            const auth = getAuth();
            signOut(auth).then(() => {
                //this.$router.push({name: 'home'});
                    window.location.href='/'
                   var username = window.localStorage.getItem("username")
                   window.localStorage.removeItem("username", username);
                
            })
            
        },
        setRequestUser() {
            this.requestUser = window.localStorage.getItem("username");
        }
        
    }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  margin-top: 60px;
}
</style>
